.pageslider-blq-slide {
    margin-bottom:0 !important;
    box-sizing: border-box;
    /*background-image: url("../../img/image-2.jpg");*/
    /*max-width: 100%;*/
    min-height: 400px !important;
    max-height: 500px !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    transform: scale(1) !important;
}

.pageslider-blq-h2 {
    color: #F5AC07;
    font-size: 4.2rem !important;
    line-height: 5rem !important;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif !important;
    font-weight: 700 !important;
}

.pageslider-blq-h3 {
    letter-spacing: 0.05rem !important;
}


.pageslider-blq-h3 {
    color: #ffffff;
    font-size: 1.5rem !important;
    line-height: 2.375rem !important;
    font-weight: 700;
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif !important;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .carousel-item {
        max-height: 450px !important;
    }

    .pageslider-blq-h2 {
        font-size: 1.75rem !important; /* 28px; */
        line-height: 2rem !important;
    }

    .pageslider-blq-h3 {
        font-size: 1rem !important; /* 16px; */
    }

}