.values-blq-margen{
    margin: 2.5rem -0.9375rem;
    border-top: 5px solid #f5ac07;
}

.values-blq-box-config{
    position: relative;
    box-sizing: border-box;
    text-align: center;
    background-color:#ffffff;
    border:1px solid #e2e2e2;
    padding: 3.125rem;
    background-position:left top;
    background-repeat:no-repeat;
    background-size:cover;
    margin-bottom: 0.625rem;
}

.values-blq-h3-title {
    padding-top: 0;
}

.values-blq-text {
    color: #000000;
}


.values-blq-text a {
    text-decoration: none;
    color: #ac8254;
}

.values-blq-text a:hover {
    color: #f5ac07 !important;
    text-decoration: none !important;
}