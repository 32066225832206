.titleandbreadcrumb-blq-container-fluid {
    background: #494949;
    color: #ffffff;
    padding: 2rem;
    font-size: 0.8rem;
    line-height: 3;
}

.titleandbreadcrumb-blq-title {
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0px;
    font-size: 1.5rem;
    text-align: left;
    /*padding-left: 0.9375rem;*/
    margin:0;
}

.titleandbreadcrumb-blq-breadcrumb a {
    color: #ac8254;
    text-decoration: none;
    font-size: 0.8rem;
}