/*bloque 3*/
.www-blq-margen{
    margin: 2.5rem -0.9375rem;
    border-top: 5px solid #f5ac07;
}

.www-blq-box-config{
    position: relative;
    box-sizing: border-box;
    text-align: center;
    background-color:#ffffff;
    border:1px solid #e2e2e2;
    padding: 0 3.125rem 3.75rem 3.125rem;
    background-position:left top;
    background-repeat:no-repeat;
    background-size:cover;
    margin-bottom: 0.625rem;
}


.www-blq-button a {
    width: auto;
    color: #ffffff;
    background: #ac8254;
    border: 1px solid transparent;
    border-radius: 0.125rem;
    box-shadow: none !important;
    padding: 0.8125rem 1.8125rem;
    line-height: 1.0625rem;
    font-size: 0.875rem;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.www-blq-button a:hover{
    color: #ffffff;
    background-color: #f5ac07;
    border: 1px solid transparent;
}

.fusion-text, .fusion-text > h3, .fusion-text > p{
    text-align: center;
    font-size: 0.9375rem;
    padding-bottom: 5% !important;
}

.www-blq-minheight {
    min-height: 130px;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .www-blq-minheight {
        min-height: 50px;
    }

}

.fusion-text > h3{
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 1.4;
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    padding-bottom: 10%;
    padding-top: 10%;
}

.fusion-text > h3, .fusion-text > p{
    color: #000000;
}

.fusion-text > p{
    font-family: Arial !important;
}
/*.www-blq-minheight{*/
    /*min-height: 11.25rem;*/
/*}*/