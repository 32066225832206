.main_menu {
    padding-left: 0;
    padding-right: 0;
}

.main_menu > .collapse > ul > li {
    padding-left: 0.5rem;
    padding-right:0;
}

.main_menu > .collapse > ul > li > a {
    line-height: 4.75rem;
    font-size: 1.0625rem;
    color: white !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    padding-left: 1.625rem !important;
}

.main_menu > .collapse ul li a:hover {
    color: #f5ac07 !important;
}


.main_menu > .collapse ul li a.active {
    color: #f5ac07 !important;
}

.dropdown-menu-right {
    left: -125% !important;
    right: auto !important;
}

/* set the selected submenu option background color */
.main_menu > .collapse ul li a.dropdown-item.active {
    /*background-color: #ac8254;*/
    background-color: transparent;
}

.main_menu > .collapse ul li a:active {
    background-color: #ac8254;
}

.main_menu > .collapse > ul > li > a:active {
    text-decoration: none;
    background-color: inherit;
}

/*.main_menu > .collapse ul li.active a {*/
    /*color: #f5ac07;*/
/*}*/

.main_menu > .collapse > ul > li.active > a {
    color: #f5ac07 !important;
}

/*.main_menu > .collapse > ul > li.active > a {*/
    /*color: #f5ac07 !important;*/
/*}*/

/*.main_menu > .collapse ul li.active a {*/
    /*color: #f5ac07 !important;*/
/*}*/

/*remove default menu down icon to add arrow down as per the original design*/
.dropdown-toggle::after {
    display: none;
}

.espacio_menu {
    margin-left: 6px;
}

/* agrego la línea superior naranja al submenu */
.ibn-dropdown-menu {
    border-top: 3px solid #f5ac07;
}

/* me aseguro que el submenú enooorme tenga espacio suficiente */
.ibn-long-submenu{
    min-width: 800px;
}


/* navbar_sticky and navbar-fixed are intended together with jQuery code included in navbar.js to solve the sticky seccond menu */
.navbar-fixed {
    top: 0;
    z-index: 100;
    position: fixed;
    width: 100%;
}

#navbar_sticky {
    border: 0;
    /*background-color: #202020;*/
    background-color: rgba(48, 48, 48, 1);
    border-radius: 0px;
    margin-bottom: 0;
    /*height: 30px;*/
}

/* to top arrow */
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: #ac8254;
}
#return-to-top:hover i {
    color: #fff;
    top: 5px;
}


/* Extra Things */
body{background: #eee ;font-family: 'Open Sans', sans-serif;}h3{font-size: 30px; font-weight: 400;text-align: center;margin-top: 50px;}h3 i{color: #444;}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .main_menu > .collapse > ul > li > a {
        line-height: 1rem;
        font-size: 1rem;
        /*min-height: 150px;*/
    }

    .ibn-long-submenu{
        min-width: auto;
    }
}

