body {
    background-color: lightgray;
}

/* to top arrow */
#return-to-top {
    z-index: 100;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    /*display: none;*/
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: #ac8254;
}
#return-to-top:hover i {
    color: #f5ac07;
    top: 5px;
}

.hr-white {
    height:1px;
    border:none;
    color:#ffffff;
    background-color: #ffffff ;
    width:10%;
    text-align:center;
    margin: 2rem auto;
}

.hr-dark {
    height:1px;
    border:none;
    color: #dfdddd;
    background-color: #dfdddd;
    width:10%;
    text-align:center;
    margin: 2rem auto;
}

.hr-grey {
    height:1px;
    border:none;
    color: #ffffff;
    background-color: #5a6268;
    width:10%;
    text-align:center;
    margin: 2rem auto;
}

@media (min-width: 1200px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .container {
        max-width: 1240px !important;
    }
}