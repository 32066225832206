.ibnnews-blq-margen{
    margin: 2.5rem auto;
}

.ibnnews-blq-content {
    background-color: #ffffff;
    padding: 3.75rem;
}

.ibnnews-blq-h2-title {
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    line-height: 1;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0px;
    font-size: 3rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

/* just apply some height and width to the wrapper.*/
.mg-image {
    /*width:475px;
    height:600px;*/
    overflow: hidden;
}
.mg-image img {
    -webkit-transition: all 1s ease; /* Safari and Chrome */
    -moz-transition: all 1s ease; /* Firefox */
    -o-transition: all 1s ease; /* IE 9 */
    -ms-transition: all 1s ease; /* Opera */
    transition: all 1s ease;
}

.mg-image:hover img {
    -webkit-transform:scale(1.25); /* Safari and Chrome */
    -moz-transform:scale(1.25); /* Firefox */
    -ms-transform:scale(1.25); /* IE 9 */
    -o-transform:scale(1.25); /* Opera */
    transform:scale(1.25);
}



.ibnnews-blq-content-h3-title {
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.13;
    color:#ac8254;
    text-align:left;
    margin-top: 1.25rem;
}

.ibnnews-blq-content-h3-title a {
    color: #ac8254;
    text-decoration: none;
}

.ibnnews-blq-content-h3-title a:hover {
    color: #f5ac07;
    text-decoration: none;
}

.ibnnews-blq-content-dateline {
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    line-height: 1.5;
    text-align:left;
}

.ibnnews-blq-content-dateline a {
    color: #ac8254;
    text-decoration: none;
}

.ibnnews-blq-content-dateline a:hover {
    color: #f5ac07;
    text-decoration: none;
}