.questionsbanner-blq-container-fluid {
    background: #ac8254;
    color: #ffffff;
    padding: 4rem;
}

.questionsbanner-blq-title {
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    line-height: 1;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0px;
    font-size: 1.875rem;
    text-align: left;
    /*padding-left: 0.9375rem;*/
    margin:0;
}

.questionsbanner-blq-button a {
    width: auto;
    color: #ffffff;
    background: #f5ac07;
    border: 1px solid transparent;
    border-radius: 0.125rem;
    box-shadow: none !important;
    padding: 0.8125rem 1.8125rem;
    line-height: 1.0625rem;
    font-size: 0.875rem;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.questionsbanner-blq-button a:hover{
    color: #ffffff;
    background-color: #F5CC4D;
    border: 1px solid transparent;
}