.contact-blq-title {
    text-align: center;
    margin-top: 40px;
    font-size: 1.875rem;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.4;
    color: #ffffff;
}

.contact-blq-text {
    text-align: center;
    margin:20px;
    font-size: 0.9375rem;
    color:#ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
}

.contact-blq-button {
    /*margin:50px;*/
    width: auto;
    background: #ac8254;
    /*border-width: 0px;*/
    /*border-color: #ffffff;*/
    /*border-radius: 2px;*/
    /*box-shadow: none !important;*/
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    border: 1px solid #ac8254;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: #ffffff;
}

.contact-blq-button:hover{
    color: #ffffff;
    background-color: #f5ac07;
    border: 1px solid #f5ac07;

}