.engange-blq-background {
    /*background-image: url("../images/where-we-work-parallax.jpg");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3.75rem;
}

.engage-blq-title {
    text-align: center;
    margin-top: 40px;
    font-size: 3rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    line-height: 1;
    font-weight: 700;
    line-height: 1.21;
    color:#ffffff;
}


.engage-blq-sub-title {
    text-align: center;
    margin:50px;
    font-size: 1.75rem;
    font-weight: 400;
    color:#ffffff;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    line-height: 1.13;
}

.button-8{
    margin:50px;
    width: auto;
    background: #ac8254;
    border-width: 0px;
    border-color: #ffffff;
    border-radius: 2px;
    box-shadow: none !important;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    border: 1px solid transparent;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: #ffffff;
}

.button-8:hover{
    color: #ffffff;
    background-color: #f5ac07;
}
