.team-blq-container-fluid {
    /*margin-top: 2.5rem;*/
    /*margin-bottom: 2.5rem;*/
}

.team-blq-container-fluid-dark {
    background-color: #303030;
    color:white;
}

.team-blq-container-fluid-white {
    background-color: #ffffff;
}

.team-blq-container-fluid-transparent {
    background-color: transparent;
}

.team-blq-container {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}


.team-blq-h2-title {
    /*color:white;*/
    font-size: 3rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

.team-blq-white-text {
    color:white;
}

.team-blq-text {
    /*color:white;*/
    margin-left: 10px;
}

.team-blq-name {
    font-size: 1rem;
    font-weight: 900;
    /*color: white;*/
    margin-bottom: 0 !important;
}

.team-blq-name-hover-dark:hover {
    text-decoration: none;
    color:#ac8254;
}


.team-blq-title {
    font-size: 0.8rem !important;
}


.group-img{
    /*width: 330px;*/
    /*height: 200px;*/
    max-width: 370px;
    max-height: 240px;
    overflow: hidden;
    margin: 10px;
    box-sizing: border-box;
}
.group-img img {
    padding: 0;
    border: none;
    -webkit-transition: all 1s ease; /* Safari and Chrome */
    -moz-transition: all 1s ease; /* Firefox */
    -o-transition: all 1s ease; /* IE 9 */
    -ms-transition: all 1s ease; /* Opera */
    transition: all 1s ease;

}

.group-img:hover img{
    -webkit-transform:scale(1.25); /* Safari and Chrome */
    -moz-transform:scale(1.25); /* Firefox */
    -ms-transform:scale(1.25); /* IE 9 */
    -o-transform:scale(1.25); /* Opera */
    transform:scale(1.25);
}