.container-fluid-reduced {
    margin: 1.875rem !important;
    max-width: 97%;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .container-fluid-reduced {
        margin: 0 !important;
        max-width: 100%;
    }

}

.titleandsubtitlechecklist-blq-container {
    margin-top: 2.5rem;
    /*margin-bottom: 2.5rem;*/
    box-sizing: border-box;
    /*border: 1px solid lightgray;*/
    background-color: #ffffff;
}

.titleandsubtitlechecklist-blq-container-top {
    border-top: 5px solid #f5ac07;
}


.titleandsubtitlechecklist-blq-h2-title {
    font-size: 1.875rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0;
    /*margin-top: 3rem;*/
    padding-bottom: 12px;
    padding-left: 1.6rem;
}

.titleandsubtitlechecklist-blq-h3-title {
    font-size: 0.9375rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0;
    margin: 0rem 1.6rem;
}

.block-padding {
    padding: 3rem;
}
@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .block-padding {
        padding: 1rem;
    }

}


.titleandsubtitlechecklist-blq-text {
    font-size: 0.85rem;
    /*float: left;*/
}


.titleandsubtitlechecklist-links {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    padding-left: 1.6rem !important;


}


.titleandsubtitlechecklist-links li {
    /*display:block;*/
    padding-left: 1.6rem !important;
    display:block;
    text-indent: -1em;
    padding: 10px 10px 10px 12px;

}

.titleandsubtitlechecklist-links li:before {
    /*content: "";*/
    /*padding-right: 5px;*/
    /*color: #f5ac07;*/
}

.titleandsubtitlechecklist-links li i {
    /*content: ">";*/
    padding-right: 0.75rem; /* MR Tururu */
    color:#ac8254;
}


.titleandsubtitlechecklistcards-blq {
    border-left: 3rem solid #eee;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .titleandsubtitlechecklistcards-blq {
        border-left: 0;
    }

}

.card-format {
    border: 1px solid #eee;
    padding: 1rem;
    border-left: 3px solid #ac8254;
}

/*==================================================
 * Box Shadow
 * ===============================================*/
.box-shadow{
    position: relative;
}
.box-shadow:before, .box-shadow:after
{
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 15px 10px #777;
    -moz-box-shadow: 0 15px 10px #777;
    box-shadow: 0 15px 10px #777;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}
.box-shadow:after
{
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}

.one-edge-shadow {
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}