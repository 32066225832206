.prenavbar_header {
    background-color: rgba(48,48,48, 1);
}

.prenavbar_row {
    padding-left: 0;
    padding-right: 0;
}

.prenavbar_row a {
    color: #ffffff;
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    list-style: none;
    border:transparent;
}

.prenavbar_row a:hover {
    color: #ffffff;
    text-decoration: none;
}

.menu_1{
    color: #ffffff;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    list-style: none;
    border:transparent;
}

.menu_1 li {
    display:block;
    padding: 10px 10px 10px 12px;
}

.menu_1 > li {
    border-right: 1px solid #e7e6e6;
}

.menu_1 > span{
    color: #ffffff;
}

.menu_1 > ul {
    border-left: none;

}

.menu_1 > ul > li > a{
    color: #ffffff;
    border-color: #ffffff;
}

.menu_1 > ul > span > li > a{
    color: #ffffff;
}