.slider-img {
    object-fit: cover;
    object-position: center; /* Center the image within the element */
    width: 100%;
    min-height: 400px;
    max-height: 700px;
    /*margin-bottom: 1rem;*/
    /* margin-top: -100px; */
}

/*.slider-img img {*/
    /*min-height: 400px;*/
    /*margin-top: -150px;*/
/*}*/

.img-top-down {
    min-height: 400px;
    margin-top: -150px;
}

.img-left-right {
    min-width: 2100px;
    margin-left: -150px;
}

.img-up-md {
    margin-top: -150px;
}

/*.slider-img img {*/
    /*object-fit: cover;*/
    /*object-position: center; !* Center the image within the element *!*/
    /*width: 100%;*/
    /*min-height: 400px;*/
    /*max-height: 700px;*/
    /*margin-bottom: 1rem;*/
    /*!* margin-top: -100px; *!*/
/*}*/

.carousel-caption h2 {
    font-family: Raleway;
    /*font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;*/
    font-size: 5.625rem; /* 90px; */
    font-weight: 600;
    line-height: 1;
    margin-bottom: 20px;
}

.carousel-caption h3, h4 {
    font-family: Raleway;
    /*font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;*/
    font-size: 1.25rem; /* 20px; */
    margin-bottom: 20px;
    margin-top: 0;
}
.carousel-caption h3 {
    letter-spacing: 0.125rem; /* 2px !important; */
}

.h3-nigerias-premier-ibr {
    font-weight: 500 !important;
}


.h3-upside-of-risks {
    font-size: 2.5rem !important; /*40px !important; */
    font-weight: 500 !important;
}

.carousel-caption h4 {
    font-weight: 600 !important;
}

.h4-our-core-expertise {
    margin: 80px 0 !important;
}

/*.carousel,*/
/*.item,*/
/*.active {*/
    /*height: 100%;*/
/*}*/

.carousel-inner {
    height: 100%;
    /* background: #000; ab pone un fondo negro */
}

.carousel-caption{
    /* ab original: padding-bottom:80px; */
    /*
    Next lines will place the caption in the middle of the screen
    https://stackoverflow.com/questions/27279865/how-to-vertically-center-a-bootstrap-carousel-caption
    */
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
}

/* code to give movement to the slider image on activation */
.carousel-item img {
    -webkit-transition: all 20s ease; /* Safari and Chrome */
    -moz-transition: all 20s ease; /* Firefox */
    -o-transition: all 20s ease; /* IE 9 */
    -ms-transition: all 20s ease; /* Opera */
    transition: all 20s ease;

}

.carousel-item.active img.img-top-down {
    /*-webkit-transform:scale(1.25); !* Safari and Chrome *!*/
    /*-moz-transform:scale(1.25); !* Firefox *!*/
    /*-ms-transform:scale(1.25); !* IE 9 *!*/
    /*-o-transform:scale(1.25); !* Opera *!*/
    /*transform:scale(1.25);*/

    -webkit-transform:translate(0,100px); /* Safari and Chrome */
    -moz-transform:translate(0,100px); /* Firefox */
    -ms-transform:translate(0,100px); /* IE 9 */
    -o-transform:translate(0,100px); /* Opera */
    transform:translate(0,100px);
}

.carousel-item.active img.img-left-right {
    /*-webkit-transform:scale(1.25); !* Safari and Chrome *!*/
    /*-moz-transform:scale(1.25); !* Firefox *!*/
    /*-ms-transform:scale(1.25); !* IE 9 *!*/
    /*-o-transform:scale(1.25); !* Opera *!*/
    /*transform:scale(1.25);*/

    -webkit-transform:translate(100px,0); /* Safari and Chrome */
    -moz-transform:translate(100px,0); /* Firefox */
    -ms-transform:translate(100px,0); /* IE 9 */
    -o-transform:translate(100px,0); /* Opera */
    transform:translate(100px,0);
}


.p{padding:10px}

/* Background images are set within the HTML using inline CSS, not here */

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    opacity:0.6;
}




/**
 * Button
 */
.btn-transparent {
    background: transparent;
    color: #fff;
    border: 0.125rem solid #fff; /* 2px */
}
.btn-transparent:hover {
    background-color: #fff;
}

.btn-rounded {
    border-radius: 4.375rem; /* 70px; */
}

.btn-large {
    padding: 11px 45px;
    font-size: 1.125rem; /* 18px; */
}

/**
 * Change animation duration
 */
.animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100px, 0, 0);
        transform: translate3d(100px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100px, 0, 0);
        transform: translate3d(100px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}


@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100px, 0, 0);
        transform: translate3d(-100px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100px, 0, 0);
        transform: translate3d(-100px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100px, 0);
        transform: translate3d(0, 100px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100px, 0);
        transform: translate3d(0, 100px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* duplicado con el código de TCMS */
.slider-button {
    margin-top: 20px;
    width: auto;
    background: #ac8254;
    border-radius: 2px;
    box-shadow: none !important;
    padding: 13px 29px;
    line-height: 1.1rem;
    font-size: 1rem;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    border: 1px solid transparent;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: #ffffff;
    /*ab */
    font-family: Raleway;
    font-weight: 600;
}

/* ab ATENCIÓN CAMBIAR ESTO EN LO QUE ME PASE TERE */
.slider-button:hover{
    color: #000000;
    background-color: #f5ac07;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .carousel-item {
        min-height: 300px;
        max-height: 300px !important;
    }

    /*.slider-img img {*/
    /*min-height: 400px;*/
    /*margin-top: -50px;*/
    /*}*/

    .img-top-down {
        min-height: 400px;
        margin-top: -50px;
    }

    .img-left-right {
        min-width: 1050px;
        margin-left: -50px;
    }

    .img-up-md {
        margin-top: 0;
    }

    .carousel-caption h2 {
        font-size: 1.75rem; /* 28px; */
    }

    .carousel-caption h3, h4 {
        font-size: 1rem; /* 16px; */
    }

    .h3-upside-of-risks {
        font-size: 1rem !important; /*40px !important; */
        font-weight: 500 !important;
    }

    .h4-our-core-expertise {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }

    .vertical-align {
        display: block;
    }

    /* code to give movement to the slider image on activation */
    /*.carousel-item img {*/
        /*-webkit-transition: all 10s ease; !* Safari and Chrome *!*/
        /*-moz-transition: all 10s ease; !* Firefox *!*/
        /*-o-transition: all 10s ease; !* IE 9 *!*/
        /*-ms-transition: all 10s ease; !* Opera *!*/
        /*transition: all 10s ease;*/

    /*}*/

    .carousel-item.active img.img-top-down {
        /*-webkit-transform:scale(1.25); !* Safari and Chrome *!*/
        /*-moz-transform:scale(1.25); !* Firefox *!*/
        /*-ms-transform:scale(1.25); !* IE 9 *!*/
        /*-o-transform:scale(1.25); !* Opera *!*/
        /*transform:scale(1.25);*/

        -webkit-transform:translate(0,40px); /* Safari and Chrome */
        -moz-transform:translate(0,40px); /* Firefox */
        -ms-transform:translate(0,40px); /* IE 9 */
        -o-transform:translate(0,40px); /* Opera */
        transform:translate(0,40px);
    }

    .carousel-item.active img.img-left-right {
        /*-webkit-transform:scale(1.25); !* Safari and Chrome *!*/
        /*-moz-transform:scale(1.25); !* Firefox *!*/
        /*-ms-transform:scale(1.25); !* IE 9 *!*/
        /*-o-transform:scale(1.25); !* Opera *!*/
        /*transform:scale(1.25);*/

        -webkit-transform:translate(40px,0); /* Safari and Chrome */
        -moz-transform:translate(40px,0); /* Firefox */
        -ms-transform:translate(40px,0); /* IE 9 */
        -o-transform:translate(40px,0); /* Opera */
        transform:translate(40px,0);
    }
}

@media (max-width: 768px) {
    /* CSS that should be displayed if width is equal to or less than 768px goes here */

    .img-left-right {
        min-width: 850px;
        margin-left: -50px;
    }
}

@media (max-width: 576px) {
    /* CSS that should be displayed if width is equal to or less than 576pc goes here */

    .img-left-right {
        min-width: 650px;
        margin-left: -50px;
    }
}