.footer-widget-area {
    color: #ffffff;
    font-size: 0.9375rem;
    background-color: #494949;
    border-top: 5px solid #ac8254;
    padding: 30px 30px 50px 30px;
}

.footer-widget-area-links li a {
    color: #f5ac07;
}

.footer-widget-area-links {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.footer-widget-area-links li {
    display:block;
    text-indent: -1em;
    padding: 10px 10px 10px 12px;
    border-bottom: 1px solid #e7e6e6;
}

.footer-widget-area-links li:before {
    content: ">";
    padding-right: 5px;
    color: #f5ac07;
}

.footer-button {
    width: auto;
    background: #ac8254;
    border-radius: 2px;
    box-shadow: none !important;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 16px;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    border: 1px solid #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: #ffffff;

    /*ab */
    font-family: "PT Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
}

.footer-button:hover{
    color: #ffffff;
    background-color: #f5ac07;
    border: 1px solid #ffffff;
}