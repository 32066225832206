/* block 4 ibninsights */
.ibninsights-blq-container-fluid {
    background-color: #ffffff;
    border-top: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
    margin-bottom: 2.5rem;
}

.ibninsights-blq-container {
    padding: 0 0 3.125rem 0;
    background-color: #ffffff;
}

.ibninsights-blq-nopaddingright {
    padding-right: 0;
}

.ibninsights-blq-nopaddingleft {
    padding-left: 0;
    padding-top: 1.25rem;
}

.b-config{
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: -60px;
    margin-top: 30px;
    margin-bottom: 50px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    width: 100%;

}

.b4-head{
    /*padding-right: 8px;*/
    text-align: left;
    font-weight: 700;
    font-size: 1.875rem;
    /*margin-left: 32px;*/
    /*width: 20%;*/
    margin-top: 1.25rem;
    color: #000000;
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    line-height: 1;
}

.b4-row{
    float: left;
    /*display:inline-block;*/
    width: 60%;
    margin-top: 10px;
    margin-left: -90px;
    box-sizing: border-box;
}

/*
    acordion menu with card format
    https://www.tutorialrepublic.com/codelab.php?topic=bootstrap&file=accordion-with-plus-minus-icon
*/
.card-header {
    background-color: transparent !important;
    padding: 0 !important;
    border-bottom: 0;
    margin-top: -15px !important;
    margin-bottom: 0;
}

.card {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 2px solid #f5ac07 !important;
}

.card-body {
    box-sizing: 0;
    font-size: 1rem;
}

.contact-body img {
    width: 2.1875rem;
    padding-bottom: 0.7rem;
    padding-top: 2rem;
}

.contact-body .card-title {
    color: #333333;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    margin-bottom: 9px;
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
}

.contact-body .card-text {
    color: rgb(116, 116, 116);
    font-size: 0.9375rem;
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
}

.accordion-button {
    padding-left: 0 !important;
}

.accordion-button:hover {
    color: #ac8254 !important;
    text-decoration: none !important;
}

.accordion-button:active {
    color: #ac8254 !important;
    text-decoration: none !important;
}

.ibninsights-blq-unibalink {
    color: #ac8254 !important;
    text-decoration: none !important;
}

.bs-example{
    margin: 1.25rem;
}
.accordion .fa{
    margin-right: 0.5rem;
}

/*
    overly over image
    http://css-workshop.com/hover-box-text-over-images-on-hover-and-more/
*/
.hvrbox,
.hvrbox * {
    box-sizing: border-box;
}
.hvrbox {
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}
.hvrbox img {
    max-width: 100%;
}
.hvrbox .hvrbox-layer_bottom {
    display: block;
}
.hvrbox .hvrbox-layer_top {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.6);*/
    background: rgba(255,255,255,0.6);
    /*color: #fff;*/
    color: #000000;
    padding: 15px;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}
.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
    opacity: 1;
}
.hvrbox .hvrbox-text {
    text-align: center;
    /*font-size: 18px;*/
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.hvrbox .hvrbox-text_mobile {
    font-size: 15px;
    border-top: 1px solid rgb(179, 179, 179); /* for old browsers */
    border-top: 1px solid rgba(179, 179, 179, 0.7);
    margin-top: 5px;
    padding-top: 2px;
    display: none;
}
.hvrbox.active .hvrbox-text_mobile {
    display: block;
}


.hvrbox .hvrbox-layer_slideright {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}
.hvrbox:hover .hvrbox-layer_slideright,
.hvrbox.active .hvrbox-layer_slideright {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}