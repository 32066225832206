.iconsubtitletext-blq-container-fluid {
    background-color: #ffffff;
    /*margin-bottom: 2.5rem;*/
    padding-bottom: 4rem;
}

.iconsubtitletext-blq-container{
    background-color: #ffffff;
    /*margin-bottom: 2.5rem;*/
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 3rem 2rem;
}

.iconsubtitletext-blq-container-topcolor {
    border-top:  5px solid #f5ac07;
}

.brown-link {
    text-decoration: none;
    color: #ac8254;
}

.grey-link {
    text-decoration: none;
    color: #747474;
}

.brown-link:hover, .grey-link:hover {
    text-decoration: none;
    color: #f5ac07;
}




.h2-title {
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0px;
    font-size: 3rem;
    text-align: center;
    margin-top: 4rem;
}

.h3-title {
    margin-top: 1.25rem !important;
    padding-bottom: 0.625rem;
    font-size: 1.0625rem;
    font-weight: 600;
    min-height: 60px;
}

.p-text {
    min-height: 130px;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .h3-title {
        min-height: 10px;
    }

    .p-text {
        min-height: 50px;
    }
}


.h3-title a {
    color: #000000;
    text-decoration: none;
}

.h3-title a:hover {
    color: #f5ac07;
    text-decoration: none;
}


.services-blq-button a {
    width: auto;
    color: #ffffff;
    background: #ac8254;
    border: 1px solid transparent;
    border-radius: 0.125rem;
    box-shadow: none !important;
    padding: 0.8125rem 1.25rem;
    line-height: 1.0625rem;
    font-size: 0.875rem;
    display: inline-block;
    position: relative;
    outline: 0;
    zoom: 1;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    /*font-weight: 700;*/
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.services-blq-button a:hover{
    color: #ffffff;
    background-color: #f5ac07;
    border: 1px solid transparent;
}

.fusion-text {
    /*min-height: 180px;*/
}

.fusion-text p {
    color: #747474;
}

.fusion-text5_1{
    margin-left: 10px;
    text-align: left;
    float: left;
    color:#ffffff;
}

/*
fa, fas, far icons horizontal movement on hover
https://www.youtube.com/watch?v=5OZAgdYmiHQ
 */

.horizontal-translate-icons ul {
    /*margin: 0;*/
    /*padding: 0;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%,-50%);*/
    /*display: flex;*/
    padding: 0;
}

.horizontal-translate-icons ul li {
    position: relative;
    list-style: none;
    width: 140px;
    height: 100px;
    margin: 0 20px;
    display: inline-block;
    /*background: #ccc;*/
    /*border: 4px solid red;*/
    box-sizing: border-box;
    border-radius: 50%;
    transition: .5s;
    overflow: hidden;

}

.horizontal-translate-icons ul li .fa,
.horizontal-translate-icons ul li .fas,
.horizontal-translate-icons ul li .far {
    margin-top: 1.5rem;
    background-color:transparent;
    border-color:transparent;
    height:auto;
    width: 48px;
    line-height:normal;
    color:#ac8254;
    font-size:48px;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*font-size: 48px;*/
    /*color: blue;*/
    transition: .3s;
}

.fa-icon-format {
    margin-top: 1.5rem;
    background-color:transparent;
    border-color:transparent;
    height:auto;
    width: 48px;
    line-height:normal;
    color:#ac8254;
    font-size:48px;
    text-align: center;
}

.horizontal-translate-icons ul li .fa:nth-child(1),
.horizontal-translate-icons ul li .fas:nth-child(1),
.horizontal-translate-icons ul li .far:nth-child(1) {
    left: -50%;
    opacity: 0;
}

.horizontal-translate-icons ul li:hover .fa:nth-child(1),
.horizontal-translate-icons ul li:hover .fas:nth-child(1),
.horizontal-translate-icons ul li:hover .far:nth-child(1) {
    left: 50%;
    opacity: 1;
}

.horizontal-translate-icons ul li:hover .fa:nth-child(2),
.horizontal-translate-icons ul li:hover .fas:nth-child(2),
.horizontal-translate-icons ul li:hover .far:nth-child(2) {
    left: 150%;
    opacity: 0;
}