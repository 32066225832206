.imgandtext-blq-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 0 2.5rem;
    box-sizing: border-box;
    border: 1px solid lightgray;
    /*border-top: 5px solid #f5ac07;*/
    background-color: #ffffff;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .imgandtext-blq-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0 1rem;
        box-sizing: border-box;
        border: 1px solid lightgray;
        /*border-top: 5px solid #f5ac07;*/
        background-color: #ffffff;
    }

}

.block-color-border-top {
    border-top: 5px solid #f5ac07 !important;
}

.imageandtext-blq {
    padding: 0 45px;
}

.imgandtext-blq-img {
    margin: 44px 12px 44px 30px;
    /*float: left;*/
}

.imgandtext-blq-img-left {
    margin: 44px 30px 44px 0;
    /*float: left;*/
}

.imgandtext-blq-img-right {
    margin: 44px 0 44px 30px;
    /*float: left;*/
}

.imgandtextandcard-blq-img {
    /*margin: 0 12px 44px 30px;*/
    padding-left: 0 !important;
    /*float: left;*/
}

.imgandtextandcard-blq {
    border-left: 0.0625rem solid lightgray;
}

.imgandtextandcard-blq-contact-img {
    border-radius: 0px;
    border: 0px solid #f6f6f6;
    box-shadow: none;
    max-width: 75%;
    margin: 0.75rem 2rem;

    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.imgandtext-blq-h2-title {
    font-size: 1.875rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0;
    margin-top: 3rem;
    padding-bottom: 12px;
}

.imgandtextcard-blq-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    /*padding: 0 2.5rem;*/
    box-sizing: border-box;
    border: 1px solid lightgray;
    /*border-top: 5px solid #f5ac07;*/
    background-color: #ffffff;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .imgandtextcard-blq-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        /*padding: 0 1rem;*/
        box-sizing: border-box;
        border: 1px solid lightgray;
        /*border-top: 5px solid #f5ac07;*/
        background-color: #ffffff;
    }
    .imgandtextandcard-blq {
        border-left: none;
    }

}

.imgandtextcard-blq-h3-title {
    font-size: 0.9375rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0;
    margin: 3rem 2rem 0.75rem 2rem;
}

.imgandtextcard-blq-name {
    margin-top:1.25rem;
    font-size: 1.375rem !important;
    line-height: 1.375rem !important;
    color: #000000;
    display: block;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    text-align: center;
}

.imgandtextcard-blq-role {
    font-size: 0.9rem !important;
    font-weight: 700;
    display: block;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    text-align: center;
    line-height: 1.13;
    letter-spacing: 0px;
    margin: 0.3rem 2rem;
}

.imgandtextcard-blq-email a {
    display: grid;
}

.imgandtextcard-blq-email a i {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: #ffffff;
    background-color: #ac8254;
    border-color: #ac8254;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    padding: 0.4375rem;
}

.imgandtextcard-blq-phone{
    /*margin-top: 1.5625rem;*/
    color: #333333;
    text-align: center;
    font-size: 0.9rem;
    clear:both;
}

.imgandtextcard-blq-phone a {
    text-decoration: none;
    color: #000000;
}

.imgandtextcard-blq-phone a:hover {
    text-decoration: none;
    color: #ac8254;
}

.imgandtext-blq-text {
    font-size: 0.85rem;
    /*float: left;*/
}

.imgandtext-blq-text a {
    text-decoration: none;
    color: #ac8254;
}

.imgandtext-blq-text a:hover {
    text-decoration: none;
    color: #ac8254;
}

/*
tabs
*/

.keystrengths-blq {
    margin-top: 3rem;
}

.keystrengths-blq .tab-content .tab-pane {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
}

.keystrengths-blq .tab-content .tab-pane p {

    padding: 1.25rem;
}

.keystrengths-blq-tabs > li > a {
    color: #000000 !important;
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
    text-decoration: none;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: #eee;
}

.keystrengths-blq-tabs > li > a:hover {
    background-color: #ffffff;
}

.keystrengths-blq-tabs > li > a.active {
    border-top: 3px solid #ac8254 !important;
}


/*
flip card
*/
.imgandtext-blq-card-flip {
    margin-top: 3rem;
    border-bottom: 0 !important;
}

.imgandtext-blq-card-flip .card {
    /*border-bottom: 0 !important;*/
    border: 1px #eee solid !important;
}

@media (max-width: 992px) {
    /* CSS that should be displayed if width is equal to or less than 992px goes here */
    .imgandtext-blq-card-flip .card {
        border: 1px #eee solid !important;
        min-height: 400px !important;
    }

    .card-front {
        min-height: 400px;
    }

    .card-back {
        min-height: 400px;
    }

}

.card-flip > div {
    backface-visibility: hidden;
    transition: transform 300ms;
    transition-timing-function: linear;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    background-color: rgb(116, 116, 116);
    border-color: rgb(229, 229, 229);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(229, 229, 229);


    transform: rotateY(180deg);
    position: absolute;
    top: 0;
}

.card-back .card-body .card-title {
    font-size: 1.75rem;
    /*font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;*/
    font-family: "NHaasGroteskDSPro-75Bd", "Neue Haas Grotesk STD Bold", sans-serif;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: 0px;
}

.card-back .card-body .card-text {
    color: #e5e5e5;
}

.card-back .card-body .card-text a {
    text-decoration: none;
    color: #ac8254;
}

.card-flip:hover .card-front {
    transform: rotateY(-180deg);
}

.card-flip:hover .card-back {
    transform: rotateY(0deg);
}

.card-design {
    min-height: 300px;
    margin-bottom: 3rem;
}