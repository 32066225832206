// assets/css/global.scss

// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
//ab font awesome-free
@import "~@fortawesome/fontawesome-free/css/all.min.css";
//ab font awesome original backed-up at wutils/fontawesome_orig. To be used shall be moved to public/css
//@import "../../public/css/font-awesome.css";

//ab import Google OpenSans font
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

//ab import Adobe Neue Haas Grotesk Display Pro 75 Bold Font
@import url(https://db.onlinewebfonts.com/c/0209bfe594ed24b75ee2aac06529e4f9?family=NHaasGroteskDSPro-75Bd);

//@import "~bootswatch/dist/yeti/variables";
@import "~bootstrap/scss/bootstrap";
//@import "~bootswatch/dist/yeti/bootswatch";