.ourculture-blq-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-top: 5px solid #f5ac07;
    background-color: #ffffff;
}

.ourculture-blq-img {
    margin: 44px 12px 44px 30px;
    float: left;
}

.ourculture-blq-h1-title {
    font-size: 1.875rem;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0;
    margin-top: 48px;
    padding-bottom: 12px;
}

.ourmission-blq-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
    border: 1px solid lightgray;
    background-color: #ffffff;
}

.ourmission-blq-h3-title {
    margin-top: 0 !important;
    font-size: 1.6rem;
    line-height: 1.4375rem;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0;
}

.ourmission-blq-h3-title a {
    color: #000000;
    text-decoration: none;
}

.ourmission-blq-h3-title a:hover {
    color: #ac8254;
    text-decoration: none;
}

.ourmission-blq-fa-icon {
    background-color:transparent;
    border-color:transparent;
    height:auto;
    width: 60px;
    line-height:normal;
    color:#ac8254;
    font-size:60px;
    margin-bottom: 15px;
}

.theibnstory-blq-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    /*padding: 3rem;*/
    /*box-sizing: border-box;*/
    /*border: 1px solid lightgray;*/
    /*background-color: #ffffff;*/
    background-color: lightgray;

}

.theibnstory-blq-col {
    padding: 3rem;
    box-sizing: border-box;
    border: 1px solid lightgray;
    background-color: #ffffff;
}

.theibnstory-blq-h2-title {
    margin-top: 1em;
    margin-bottom: .7em;
    font-size: 1.875rem;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0px;
}

.theibnstory-blq-img {
    float:left;
}

.theibnstory-blq-tabs > li > a {
    color: #000000 !important;
    background-color: #eee;
    font-size: 0.8rem !important;
    text-decoration: none;
    font-family: "Neue Haas Grotesk STD Bold", Arial, Helvetica, sans-serif;
    font-weight: 600 !important;
    line-height: 1.13;
    letter-spacing: 0px;
}

.theibnstory-blq-tabs > li > a:hover {
    background-color: #ffffff;
}

.theibnstory-blq-tabs > li > a.active {
    border-top: 3px solid #ac8254 !important;
}

/*panel horizontal*/
#insurance-tab, #irc-tab, #rba-tab, #wholesale-tab{

    font-size: 12px;
    font-weight: bold;
}
#myTab > li {
    width: 25%;
}

.theibnstory-blq-link {
    color: #ac8254;
    text-decoration: none;
}

.theibnstory-blq-link:hover {
    color: #ac8254;
    text-decoration: none;
}




.boton-6 {
    font-family: "PT Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0px;

    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: white;
    background-color: #ac8254;
    margin: 2.5rem;
    justify-content: center;
    border: 2px solid white;
    padding: 1rem 2.5rem;
}
.boton-6:hover{
    color: #ffffff;
    background-color: #f5ac07;
    border:2px solid white;
}