.footer-copyright-area {
    z-index: 10;
    position: relative;
    padding: 18px 30px 25px 30px;

    border-top: 1px solid #ac8254;
    background-color: #ffffff;
    color: #333333;
    font-size: 0.75rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
}

.footer-copyright-text {
    color: #999999;
}

.footer-copyright-text a {
    color: #ac8254;
}

.footer-copyright-text a:hover {
    color: #f5ac07;
}

.footer-copyright-links li a {
    color: #494949;
}